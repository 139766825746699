import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback } from 'react';
import { useState } from 'react';
import MagnifyingGlassIcon from '../icons/MagnifyingGlassIcon';
import './style.scss';

type SearchBarProps = {
  onSubmitQuery?: (query: string) => void;
  className?: string;
  initialQuery?: string | null;
};

const SearchBar: React.FC<SearchBarProps> = ({
  onSubmitQuery,
  className,
  initialQuery,
}) => {
  const { t } = useTranslation();

  const [inputText, setInputText] = useState(initialQuery ?? '');

  const onSubmit = useCallback<
    React.FormEventHandler<HTMLFormElement | SVGSVGElement>
  >(
    e => {
      e.preventDefault();
      onSubmitQuery?.(inputText);
    },
    [inputText, onSubmitQuery]
  );

  const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    e => {
      setInputText(e.target.value);
    },
    []
  );

  return (
    <form className={`bar ${className}`} onSubmit={onSubmit}>
      <button type="submit" className="bar__lens" tabIndex={2}>
        <MagnifyingGlassIcon onClick={onSubmit} />
      </button>

      <input
        onChange={onChange}
        tabIndex={1}
        value={inputText}
        className="bar__input"
        type="text"
        id="seach_input"
        placeholder={t('search_bar_placeholder')}
      />
    </form>
  );
};

export default React.memo(SearchBar);
