import classNames from 'classnames';
import { range } from 'ramda';
import React, { useMemo } from 'react';
import Link from '../Link';
import Text from '../Text';
import ChevronLeft from '../icons/ChevronLeft';
import ChevronRight from '../icons/ChevronRight';
import './style.scss';

export type GetUrlFromIndex = (index: number) => string;

type PaginationBlockProps = {
  currentIndex: number;
  maxIndex: number;
  getUrlFromIndex: GetUrlFromIndex;
};

const PaginationBlock: React.FC<PaginationBlockProps> = ({
  currentIndex,
  maxIndex,
  getUrlFromIndex,
}) => {
  const pageIndexes = useMemo(() => {
    const showAllBefore = currentIndex < 5;
    const showAllAfter = maxIndex - currentIndex < 4;

    return [
      ...(showAllBefore
        ? range(1, currentIndex + 1)
        : [1, -(currentIndex - 2), currentIndex - 1, currentIndex]),
      ...(showAllAfter
        ? range(currentIndex + 1, maxIndex + 1)
        : [currentIndex + 1, -(currentIndex + 2), maxIndex]),
    ];
  }, [currentIndex, maxIndex]);

  const Pages = useMemo(
    () =>
      pageIndexes.map(index => (
        <Link
          href={getUrlFromIndex(index)}
          key={index}
          className={classNames(
            'pagination-block-circle pagination-block__circle',
            { 'pagination-block-circle_selected': currentIndex === index }
          )}
        >
          <Text
            type="p"
            className={classNames('pagination-block-circle__text', {
              'pagination-block-circle__text_selected': currentIndex === index,
            })}
          >
            {index > 0 ? index : '...'}
          </Text>
        </Link>
      )),
    [currentIndex, getUrlFromIndex, pageIndexes]
  );

  return (
    <div className="pagination-block">
      {currentIndex !== 1 && (
        <Link
          href={getUrlFromIndex(currentIndex - 1)}
          className="pagination-block-circle pagination-block__circle"
        >
          <ChevronLeft className="pagination-block-circle__chevron" />
        </Link>
      )}
      {Pages}
      {currentIndex !== maxIndex && (
        <Link
          href={getUrlFromIndex(currentIndex + 1)}
          className="pagination-block-circle pagination-block__circle"
        >
          <ChevronRight className="pagination-block-circle__chevron pagination-block-circle__chevron_reversed" />
        </Link>
      )}
    </div>
  );
};

export default React.memo(PaginationBlock);
