import * as React from 'react';
import { SVGProps, memo } from 'react';

const MagnifyingGlassIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 11c0-3.309 2.69-6 6-6 3.309 0 6 2.691 6 6s-2.691 6-6 6c-3.31 0-6-2.691-6-6Zm15.707 8.293-3.395-3.396A7.952 7.952 0 0 0 19 11c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8a7.952 7.952 0 0 0 4.897-1.688l3.396 3.395a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414Z"
      fill="#13171E"
      opacity={0.4}
    />
  </svg>
);

const Memo = memo(MagnifyingGlassIcon);
export default Memo;
