import { HeadProps, PageProps, graphql } from 'gatsby';
import { LocaleNode } from 'gatsby-plugin-react-i18next/dist/types';
import React from 'react';
import ArticleList from '../../components/ArticleList';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

type DataProps = {
  allSanityArticle: { nodes: any[] };
  locales: { edges: Array<{ node: LocaleNode }> };
};

type PageContextType = {
  pageNumber: number;
  filter: any;
  overallPageNumber: number;
  limit: number;
};

const Article = ({
  data: {
    allSanityArticle: { nodes: articles },
  },
  pageContext: { filter, pageNumber, overallPageNumber, limit: articlesOnPage },
}: PageProps<DataProps, PageContextType>) => {
  const selectedTag = filter?.tags?.in?.[0];

  return (
    <Layout>
      <Navigation headerBackgroundColor="light" />

      <ArticleList
        articles={articles}
        selectedTag={selectedTag}
        pageNumber={pageNumber}
        overallPageNumber={overallPageNumber}
        articlesOnPage={articlesOnPage}
      />

      <Footer noFooterCard={false} />
    </Layout>
  );
};

export const query = graphql`
  fragment ListArticle on SanityArticle {
    title
    slug {
      current
    }
    id
    tags
    boxes {
      __typename
      ... on SanityTextBlock {
        text
        _type
      }
      ... on SanityBlockQuote {
        body
        _type
      }
      ... on SanityInfoBlock {
        body
        _type
      }
      ... on SanityCallToActionBlock {
        _type
      }
      ... on SanityReadAlsoBlock {
        _type
      }
      ... on SanityTextTable {
        _type
      }
      ... on SanityCaptionedImage {
        _type
      }
      ... on SanityCardBlock {
        _type
      }
    }
  }

  query SanityArticle(
    $filter: SanityArticleFilterInput
    $language: String!
    $skip: Int!
    $limit: Int!
  ) {
    allSanityArticle(
      filter: $filter
      sort: { fields: _createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ListArticle
        headerImage {
          mainImage {
            asset {
              gatsbyImageData(height: 210, placeholder: BLURRED)
            }
          }
          altText
          caption
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({
  data,
  pageContext: { filter, pageNumber },
}: HeadProps<DataProps, PageContextType>) => {
  const selectedTag = filter?.tags?.in?.[0];

  // TODO: replace with useTranslation once Context is available in Gatsby Head API
  // https://github.com/gatsbyjs/gatsby/issues/36458#issuecomment-1816040142
  // https://github.com/gatsbyjs/gatsby/discussions/35841#discussioncomment-3258990

  const dataLanguage = data.locales.edges.find(
    (e) => e.node.ns === 'translation'
  )!.node.data;

  const t: Record<string, string> = JSON.parse(dataLanguage);

  const metaTitle = selectedTag
    ? t['blog_meta_title_tagged'].replace('{{tag}}', t[selectedTag])
    : t['blog_meta_title'];

  const metaTitlePaginated = metaTitle.replace('{{page}}', String(pageNumber));

  const metaDescription = selectedTag
    ? t['blog_meta_description_tagged'].replace('{{tag}}', t[selectedTag])
    : t['blog_meta_description'];

  const metaDescriptionPaginated = metaDescription.replace(
    '{{page}}',
    String(pageNumber)
  );

  return (
    <SEO title={metaTitlePaginated} description={metaDescriptionPaginated} />
  );
};

export default React.memo(Article);
