import { useTranslation } from 'gatsby-plugin-react-i18next';
import { isEmpty } from 'ramda';
import React, { useCallback, useMemo } from 'react';
import useArticlePreviewData from '../../hooks/useArticlePreviewData';
import { SEARCH_QUERY_PARAMETER_NAME } from '../../theme/constants';
import { PreviewArticleType, Tag } from '../../utils/blogTypes';
import ArticlePreview from '../ArticlePreview';
import ArticleTag, { TagType } from '../ArticleTag';
import LoadingIndicator from '../LoadingIndicator';
import PaginationBlock, { GetUrlFromIndex } from '../PaginationBlock';
import SearchBar from '../SearchBar';
import Text from '../Text';
import './style.scss';

const tags = Object.values(Tag);

type ArticleListProps = {
  selectedTag: string | undefined;
  articles: PreviewArticleType[];
  pageNumber: number;
  overallPageNumber: number;
  articlesOnPage: number;
};

const ArticleList: React.FC<ArticleListProps> = ({
  selectedTag,
  articles: initialArticles,
  pageNumber,
  overallPageNumber: initialOverallPageNumber,
  articlesOnPage,
}) => {
  const { t } = useTranslation();

  const { articles, loading, onSubmitQuery, urlQuery, overallPageNumber } =
    useArticlePreviewData({
      initialArticles,
      tag: selectedTag,
      currentPage: pageNumber,
      articlesOnPage,
      initialOverallPageNumber,
    });

  const renderTag = useCallback<(tag: Tag) => JSX.Element>(
    (tag) => (
      <ArticleTag
        className="filters__tag"
        tagValue={tag}
        tagType={tag === selectedTag ? TagType.ENABLED : TagType.DISABLED}
        href={`/blog/tag/${tag}/`}
      />
    ),
    [selectedTag]
  );

  const renderArticlePreview = useCallback<
    (article: PreviewArticleType) => JSX.Element
  >(
    (article) => (
      <ArticlePreview {...article} className="article__article-item" />
    ),
    []
  );

  const Articles = useMemo(
    () =>
      loading ? (
        <LoadingIndicator className="list__loading-indicator" />
      ) : isEmpty(articles) ? (
        <Text className="list__nothing-found h4" type="p">
          {t('nothing_found')}
        </Text>
      ) : (
        articles?.map(renderArticlePreview)
      ),
    [articles, loading, renderArticlePreview, t]
  );

  const getUrlFromIndex = useCallback<GetUrlFromIndex>(
    (index) => {
      const urlSuffix = urlQuery
        ? encodeURI(`?${SEARCH_QUERY_PARAMETER_NAME}=${urlQuery}`)
        : '';

      const urlPrefix = selectedTag
        ? `/blog/tag/${selectedTag}/page/`
        : '/blog/page/';

      if (index === 1) return `/blog${urlSuffix}`;

      return `${urlPrefix}${Math.abs(index)}${urlSuffix}`;
    },
    [selectedTag, urlQuery]
  );

  return (
    <div className="list">
      <Text weight="bold" type="h1" color="dark" className="list__title">
        {t('blog_homepage_title')}
      </Text>
      <div className="filters list__filters">
        <ArticleTag
          className="filters__tag"
          tagType={selectedTag ? TagType.DISABLED : TagType.ENABLED}
          href="/blog"
        />
        {tags?.map(renderTag)}
        <SearchBar
          onSubmitQuery={onSubmitQuery}
          initialQuery={urlQuery}
          className="filters__search-bar"
        />
      </div>
      <div className="article">{Articles}</div>
      <div className="see-more list__see-more">
        <PaginationBlock
          currentIndex={pageNumber}
          maxIndex={overallPageNumber}
          getUrlFromIndex={getUrlFromIndex}
        />
      </div>
    </div>
  );
};

export default React.memo(ArticleList);
