import React from 'react';
import './style.scss';

const LoadingIndicator = ({ className }: { className?: string }) => {
  return (
    <div className={`lds-ring ${className}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default React.memo(LoadingIndicator);
