import { navigate } from 'gatsby';
import { useCallback, useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { SEARCH_QUERY_PARAMETER_NAME } from '../theme/constants';
import { PreviewArticleType } from '../utils/blogTypes';

type ArticleQueryParams = {
  start: number;
  end: number;
  tag?: string;
  query?: string;
};

const getArticleGroqQuery = ({
  start,
  end,
  tag,
  query,
}: ArticleQueryParams) => `
https://${
  process.env.GATSBY_SANITY_PROJECT_ID
}.apicdn.sanity.io/v2022-01-24/data/query/production?
query=*[_type%20==%20%27article%27 ${tag ? ` %26%26"${tag}"+in+tags` : ''}
${query ? `%26%26 title+match+"*${query}*"` : ''}
]%20|%20
order(_createdAt%20desc)%20
{_id,
headerImage->
  {caption,
   altText, 
   mainImage{asset->{url}}}, 
   title,
   slug,
   tags, 
   boxes[]->{_type, text, body, pros, cons}}
[${start}...${end}]`;

const getArticleQuantityGroqQuery = ({
  tag,
  query,
}: Omit<ArticleQueryParams, 'start' | 'end'>) => `
https://${
  process.env.GATSBY_SANITY_PROJECT_ID
}.apicdn.sanity.io/v2022-01-24/data/query/production?
query={"size": count(*[_type%20==%20%27article%27 ${
  tag ? ` %26%26"${tag}"+in+tags` : ''
}
${query ? `%26%26 title+match+"*${query}*"` : ''}
])}`;

const useArticlePreviewData = ({
  initialArticles,
  tag,
  articlesOnPage,
  currentPage,
  initialOverallPageNumber,
}: {
  initialArticles: PreviewArticleType[];
  tag?: string;
  articlesOnPage: number;
  currentPage: number;
  initialOverallPageNumber: number;
}) => {
  const [loading, setLoading] = useState(false);
  const [urlQuery] = useQueryParam<string | null | undefined>(
    SEARCH_QUERY_PARAMETER_NAME,
    StringParam
  );
  const [overallPageNumber, setOverallPageNumber] = useState(
    initialOverallPageNumber
  );

  const [dynamicArticles, setDynamicArticles] = useState<
    PreviewArticleType[] | null
  >(null);

  const articles = urlQuery ? dynamicArticles : initialArticles;

  useEffect(() => {
    if (!urlQuery) {
      setDynamicArticles(null);
      return;
    }
    setLoading(true);

    const startIndex = articlesOnPage * (currentPage - 1);

    fetch(
      getArticleGroqQuery({
        start: startIndex,
        end: startIndex + articlesOnPage,
        tag,
        query: urlQuery,
      })
    ).then(rawResult => {
      rawResult.json().then(({ result }) => {
        setDynamicArticles(result);
        setLoading(false);
      });
    });

    fetch(getArticleQuantityGroqQuery({ query: urlQuery, tag })).then(
      rawResult => {
        rawResult.json().then(({ result }) => {
          setOverallPageNumber(
            Math.max(Math.ceil((result.size ?? 1) / articlesOnPage), 1)
          );
        });
      }
    );
  }, [articlesOnPage, currentPage, tag, urlQuery]);

  const onSubmitQuery = useCallback(
    (inputQuery: string) => {
      const baseUrl = `/blog${tag ? `/tag/${tag}` : ''}`;
      navigate(
        inputQuery
          ? encodeURI(`${baseUrl}?${SEARCH_QUERY_PARAMETER_NAME}=${inputQuery}`)
          : baseUrl
      );
    },
    [tag]
  );

  return {
    articles,
    loading,
    onSubmitQuery,
    urlQuery,
    overallPageNumber,
  };
};

export default useArticlePreviewData;
